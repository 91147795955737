import React from 'react';
import { ModalsProvider } from './Modal/use-one-time-modal';
import { useRetention } from './useRetention';

const RootLayout = ({ children }) => {
  useRetention();
  return (
    <>
      <ModalsProvider>
        <div>{children}</div>
      </ModalsProvider>
    </>
  );
};

export default RootLayout;
