import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import React from 'react';

export enum ModalType {
  subscribe = 'modals/subscribe',
  HeardAboutUs = 'HeardAboutUs',
}

export const ModalsContext = createContext(
  {} as {
    stack: string[];
    setStack: Dispatch<SetStateAction<string[]>>;
    // we have to use any for this since it is generic for all modal
    props: any;
    setModalProps: Dispatch<SetStateAction<any[]>>;
  }
);

type Props = {
  children: ReactNode;
};
export const ModalsProvider = ({ children }: Props) => {
  const [stack, setStack] = useState<string[]>([]);
  const [props, setModalProps] = useState({});

  return (
    <ModalsContext.Provider value={{ stack, setStack, props, setModalProps }}>
      {children}
    </ModalsContext.Provider>
  );
};

export function useModalProps<Props>() {
  return useContext(ModalsContext).props as Props;
}

interface UseModalResult<T> {
  getIsOpen: (key: T) => boolean;
  hasOpened: boolean;
  // we have to use any for this since it is generic for all modal
  open: (key: T, data?: any) => void;
  // close used to close modal session
  close: (key: T) => void;
  // hide used to close modal
  hide: () => void;
  clear: () => void;
  hasAnyOpen: boolean;
}

export const useOneTimeModal = <
  T extends string = ModalType
>(): UseModalResult<T> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore ignored because atom could not be generic
  const { stack, setStack, setModalProps } = useContext<T[]>(ModalsContext);

  const getIsOpen = useCallback(
    (key: T) => {
      const current = stack?.[0];

      return Boolean(current === key);
    },
    [stack]
  );
  const hasOpened = stack?.length > 0;
  const open = useCallback(
    // we have to use any for this since it is generic for all modal
    (key: T, data?: any) => {
      // We only want one active modal at a time, please call out if there is some location that needed multiple at once
      // Without this change, the upgrade modal cannot pop up the platinum plan modal
      setStack([key]);
      if (data != null) setModalProps(data);
    },
    [setStack, setModalProps]
  );
  const hide = useCallback(
    () => setStack((val: T[]) => val.slice(1)),
    [setStack]
  );
  const close = useCallback(
    (key: T) => {
      setStack((val: T[]) => val.filter((item) => item !== key));
      setModalProps({});
    },
    [setStack, setModalProps]
  );
  const clear = useCallback(() => setStack([]), [setStack]);

  return {
    getIsOpen,
    hasOpened,
    open,
    hide,
    close,
    clear,
    hasAnyOpen: stack?.length > 0,
  };
};

type ReplaceStateFunction = (
  pathname: string,
  query: Record<string, any>,
  options?: { shallow: boolean }
) => void;

export function useModalOpenState(
  isOpen: boolean,
  replaceState: ReplaceStateFunction,
  currentQuery: Record<string, any>,
  pathname: string
) {
  useEffect(() => {
    if (!isOpen || currentQuery?.modalOpened === 'true') return;

    const newQuery = { ...currentQuery, modalOpened: 'true' };
    replaceState(pathname, newQuery, { shallow: true });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    if (window != null && window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      window.Intercom('hide');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      window.Intercom('update', {
        last_request_at: parseInt((new Date().getTime() / 1000).toFixed()),
      });
    }
  }, [currentQuery, isOpen, pathname, replaceState]);

  return {
    onClose: useCallback(() => {
      const { modalOpened, ...excludeModalOpened } = currentQuery;
      replaceState(pathname, excludeModalOpened, { shallow: true });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      if (window != null && window.Intercom != null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        window.Intercom('update', {
          last_request_at: parseInt((new Date().getTime() / 1000).toFixed()),
        });
      }
    }, [currentQuery, pathname, replaceState]),
  };
}
