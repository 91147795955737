exports.components = {
  "component---src-components-blog-blog-post-template-tsx": () => import("./../../../src/components/Blog/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-components-blog-blog-post-template-tsx" */),
  "component---src-components-provider-template-tsx": () => import("./../../../src/components/ProviderTemplate.tsx" /* webpackChunkName: "component---src-components-provider-template-tsx" */),
  "component---src-components-sportsbook-template-tsx": () => import("./../../../src/components/SportsbookTemplate.tsx" /* webpackChunkName: "component---src-components-sportsbook-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-market-intelligence-tsx": () => import("./../../../src/pages/market-intelligence.tsx" /* webpackChunkName: "component---src-pages-market-intelligence-tsx" */),
  "component---src-pages-odds-screen-tsx": () => import("./../../../src/pages/odds-screen.tsx" /* webpackChunkName: "component---src-pages-odds-screen-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-resources-news-tsx": () => import("./../../../src/pages/resources/news.tsx" /* webpackChunkName: "component---src-pages-resources-news-tsx" */),
  "component---src-pages-sgp-insight-tsx": () => import("./../../../src/pages/sgp-insight.tsx" /* webpackChunkName: "component---src-pages-sgp-insight-tsx" */),
  "component---src-pages-sports-betting-api-tsx": () => import("./../../../src/pages/sports-betting-api.tsx" /* webpackChunkName: "component---src-pages-sports-betting-api-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

