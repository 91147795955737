import { useEffect } from 'react';

export function useRetention() {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    function startRetention() {
      // @ts-ignore
      const reb2b = (window.reb2b = window.reb2b || []);
      if (reb2b.invoked) return;
      reb2b.invoked = true;
      reb2b.methods = ['identify', 'collect'];
      reb2b.factory = function (method: any) {
        return function () {
          var args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          reb2b.push(args);
          return reb2b;
        };
      };
      for (var i = 0; i < reb2b.methods.length; i++) {
        var key = reb2b.methods[i];
        reb2b[key] = reb2b.factory(key);
      }
      reb2b.load = function (key: string) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src =
          'https://s3-us-west-2.amazonaws.com/b2bjsstore/b/' +
          key +
          '/reb2b.js.gz';
        var first = document.getElementsByTagName('script')[0];
        // @ts-ignore
        first.parentNode.insertBefore(script, first);
      };
      reb2b.SNIPPET_VERSION = '1.0.1';
      reb2b.load('4O7Z0HMDKJNX');
    }
    startRetention();
  }, []);
}
